// import React from "react";
// import { Grid, Button, Stack, Typography, Switch } from "@mui/material";
// import { Button as IconButton } from "antd";
// import MainCard from "components/MainCard";
// import { DoubleRightOutlined } from "@ant-design/icons";
// import { useLocation, useNavigate } from "react-router-dom";

// const MainDashboard = () => {
//   const navigate = useNavigate();
//   return (
//     <div>
//       <Grid container spacing={3}>
//         <Grid container item lg={4}>
//           <MainCard>
//             <Grid container item lg={12}>
//               <Grid container item lg={9}>
//                 <Grid item lg={12}>
//                   <Typography variant="h3">Machine Maintenance</Typography>
//                 </Grid>
//                 <Grid>
//                   <Switch />
//                 </Grid>
//               </Grid>
//               <Grid item lg={3}>
//                 <Stack
//                   direction="row"
//                   justifyContent="center"
//                   alignItems="center"
//                 >
//                   <IconButton
//                     type="primary"
//                     onClick={() => {
//                       navigate("/asset");
//                     }}
//                     icon={<DoubleRightOutlined style={{ fontSize: "50px" }} />}
//                     style={{ width: "80px", height: "80px" }}
//                   />
//                 </Stack>
//               </Grid>
//             </Grid>
//           </MainCard>
//         </Grid>
//         <Grid container item lg={4}>
//           <MainCard>
//             <Grid container item lg={12}>
//               <Grid container item lg={9}>
//                 <Grid item lg={12}>
//                   <Typography variant="h3">Ticket Management</Typography>
//                 </Grid>
//                 <Grid>
//                   <Switch />
//                 </Grid>
//               </Grid>
//               <Grid item lg={3}>
//                 <Stack
//                   direction="row"
//                   justifyContent="center"
//                   alignItems="center"
//                 >
//                   <IconButton
//                     type="primary"
//                     onClick={() => {
//                       navigate("/tickets");
//                     }}
//                     icon={<DoubleRightOutlined style={{ fontSize: "50px" }} />}
//                     style={{ width: "80px", height: "80px" }}
//                   />
//                 </Stack>
//               </Grid>
//             </Grid>
//           </MainCard>
//         </Grid>
//         <Grid container item lg={4}>
//           <MainCard>
//             <Grid container item lg={12}>
//               <Grid container item lg={9}>
//                 <Grid item lg={12}>
//                   <Typography variant="h3">Visitor Management</Typography>
//                 </Grid>
//                 <Grid>
//                   <Switch />
//                 </Grid>
//               </Grid>
//               <Grid item lg={3}>
//                 <Stack
//                   direction="row"
//                   justifyContent="center"
//                   alignItems="center"
//                 >
//                   <IconButton
//                     type="primary"
//                     onClick={() => {
//                       navigate("/visitor-details");
//                     }}
//                     icon={<DoubleRightOutlined style={{ fontSize: "50px" }} />}
//                     style={{ width: "80px", height: "80px" }}
//                   />
//                 </Stack>
//               </Grid>
//             </Grid>
//           </MainCard>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// export default MainDashboard;


import React, { useState } from 'react';
import { TreeSelect } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

const YourComponent = () => {
  const [machine, set_machine] = useState(null);
  const machine_details_list = [
    {
      title: 'Parent 1',
      value: 'parent1',
      children: [
        { title: 'Child 1', value: 'child1' },
        { title: 'Child 2', value: 'child2' },
      ],
    },
    {
      title: 'Parent 2',
      value: 'parent2',
      children: [
        { title: 'Child 3', value: 'child3' },
        { title: 'Child 4', value: 'child4' },
      ],
    },
  ];

  const transformData = (data) => {
    return data.map(({ children, ...item }) => {
      if (children) {
        return {
          ...item,
          children: transformData(children),
        };
      }
      return { ...item };
    });
  };

  const getChildValues = (data) => {
    let childValues = [];
    data.forEach((item) => {
      if (item.children) {
        childValues = [...childValues, ...getChildValues(item.children)];
      } else {
        childValues.push(item.value);
      }
    });
    return childValues;
  };

  const childValues = getChildValues(machine_details_list);

  const filteredData = transformData(machine_details_list);

  const handleChange = (value) => {
    if (childValues.includes(value)) {
      set_machine(value);
    } else {
      set_machine(null);
    }
  };

  return (
    <TreeSelect
      showSearch
      suffixIcon={<CaretDownOutlined />}
      style={{
        width: '100%',
        border: '0.5px solid #D9D9D9',
        borderRadius: '3px',
      }}
      filterTreeNode={(search, item) => {
        return (
          item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
        );
      }}
      treeNodeFilterProp="title"
      value={machine}
      bordered={false}
      dropdownStyle={{
        maxHeight: 400,
        overflow: 'auto',
      }}
      placeholder="Select Asset"
      allowClear
      size="large"
      // treeDefaultExpandAll
      onChange={handleChange}
      treeData={filteredData}
    />
  );
};

export default YourComponent;
