import React, { useState, useEffect } from "react";
import { Stack, IconButton, Typography, FormHelperText } from "@mui/material";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleOutlined";
import PauseCircleIcon from "@mui/icons-material/PauseCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/StopCircle";
import { get_machine_time_record, save_machine_time_record } from "utils/api";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export default function Timer({
  get_status,
  set_is_started,
  ticket_id,
  machine_id,
  set_time_history_refresh,
}) {
  const [time, set_time] = useState("00:00:00");
  const [isActive, setIsActive] = useState(false);
  const [last_start_time, set_last_start_time] = useState();

  useEffect(() => {
    get_machine_time_record(success_get_machine_time_record, {
      ticket_id: ticket_id,
      machine_id: machine_id,
    });
  }, []);

  let elapsedSeconds = time && timeToSeconds(time) + last_start_time;

  function incrementTime() {
    elapsedSeconds += 1;
    const hours = Math.floor(elapsedSeconds / 3600);
    const minutes = Math.floor((elapsedSeconds % 3600) / 60);
    const seconds = elapsedSeconds % 60;
    const padWithZero = (num) => (num < 10 ? "0" + num : num);

    set_time(
      `${padWithZero(hours)}:${padWithZero(minutes)}:${padWithZero(seconds)}`
    );
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      isActive && incrementTime();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isActive]);

  const success_get_machine_time_record = async (res) => {
    await set_time(
      res?.data?.formatted_sum === "" ? "00:00:00" : res.data.formatted_sum
    );
    await set_is_started(
      (res?.data?.formatted_sum !== "" || res.data.is_active) && true
    );
    await setIsActive(res.data.is_active);
    const current = dayjs.utc().add(5, "hour").add(30, "minute");
    const last_start = dayjs(res.data.last_start_time);
    await set_last_start_time(
      last_start_time !== "None" ? getTimeDifference(current, last_start) : 0
    );
  };

  function getTimeDifference(startDate, endDate) {
    const timeDifference = Math.abs(startDate - endDate);
    return Math.floor(timeDifference / 1000);
  }

  function timeToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString?.split(":")?.map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  }

  const success_time_update = async (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      set_is_started(true);
      set_time_history_refresh && set_time_history_refresh((i) => !i);
      get_machine_time_record(success_get_machine_time_record, {
        ticket_id: ticket_id,
        machine_id: machine_id,
      });
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  const handleResume = () => {
    const time = dayjs.utc().add(5, "hour").add(30, "minute");
    save_machine_time_record(success_time_update, {
      ticket_id: ticket_id,
      machine_id: machine_id,
      start_time: time,
    });
  };

  const handlePause = () => {
    const time = dayjs.utc().add(5, "hour").add(30, "minute");
    save_machine_time_record(success_time_update, {
      ticket_id: ticket_id,
      machine_id: machine_id,
      end_time: time,
    });
  };

  const ActiveButtons = get_status && (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="center"
      sx={{
        width: "100%",
      }}
    >
      {isActive ? (
        <IconButton size="large" color="primary" onClick={handlePause}>
          <PauseCircleIcon sx={{ fontSize: "32px" }} />
        </IconButton>
      ) : (
        <IconButton size="large" color="primary" onClick={handleResume}>
          <PlayCircleFilledIcon sx={{ fontSize: "32px" }} />
        </IconButton>
      )}
    </Stack>
  );

  return (
    <React.Fragment>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h3">{time?.split(":")[0]}</Typography>
        <Typography variant="h3">:</Typography>
        <Typography variant="h3">{time?.split(":")[1]}</Typography>
        <Typography variant="h3">:</Typography>
        <Typography variant="h3">{time?.split(":")[2]}</Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        {ActiveButtons}
      </Stack>
      {get_status && (
        <FormHelperText>
          Note: Please begin the timer to start working on the ticket.
        </FormHelperText>
      )}
    </React.Fragment>
  );
}
