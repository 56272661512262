// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import auth from "./auth";
import search from "./search";
import dashboardValue from "./dashboardValue";
import recordView from "./recordView";
import accessControl from "./accessControl";
import maindashboard from "./maindashboard";
import loginShow from "./loginShow";
import otpShow from "./otpValueShow";
import forgotPasswordShow from "./forgotPassword";
import setpasswordShow from "./setpasswordshow";
import workflowData from "./workflowData";
import workflowEdges from "./workflowEdges";
import workflowNodes from "./workflowNodes";
import workflowActionList from "./workflowActionList";
import company_profile from "./company_profile";
import asset_tree from "./asset_tree";
import main_asset from "./main_asset";
import copy_from_machine from "./copy_from_machine";
import machine_edit_top from "./machine_edit";
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  auth,
  search,
  dashboardValue,
  recordView,
  forgotPasswordShow,
  accessControl,
  maindashboard,
  otpShow,
  loginShow,
  setpasswordShow,
  workflowData,
  workflowEdges,
  workflowNodes,
  workflowActionList,
  company_profile,
  asset_tree,
  main_asset,
  copy_from_machine,
  machine_edit_top,
});

export default reducers;
