// material-ui
import { Link, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleDownloadbase64ToFile } from "components/Base64toFile";
import privacy from "../../pages/company-details/privacy-policy.pdf";
import terms from "../../pages/company-details/Terms-Conditions.pdf";
import about from "../../pages/company-details/Contact-Us.pdf";
import versionfile from "../../assets/third-party/version.json";
import { Popover, Button } from "antd";

const Footer = ({ color }) => {
  const {
    company_name,
    company_url,
    company_logo,
    theme_color,
    privacy_policy,
    terms_conditions,
    about_us,
  } = useSelector((state) => state.company_profile);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ p: "12px 16px 12px", mt: "auto" }}
    >
      <Typography variant="caption" sx={{ color: color }}>
        &copy; {new Date().getFullYear()} Powered by{" "}
        <Link
          target="_blank"
          href={company_url ? company_url : "https://www.nexgensis.com/"}
        >
          {company_name ? company_name : "Nexgensis"}
        </Link>
      </Typography>
      <Stack
        spacing={1.5}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Popover
          content={
            <div>
              <p>
                <b>Branch</b>: {versionfile.Branch}
              </p>
              <p>
                <b>Version</b>: {versionfile.Version}
              </p>
              <p>
                <b>Date</b>: {versionfile.Date}
              </p>
            </div>
          }
          trigger="click"
        >
          <Link variant="caption" color="textPrimary" sx={{ color: color }}>
            Version
          </Link>
        </Popover>

        {privacy_policy ? (
          <Link
            variant="caption"
            color="textPrimary"
            onClick={() =>
              handleDownloadbase64ToFile(
                privacy_policy?.split(",")[1],
                "Privacy-Policy"
              )
            }
            sx={{ color: color }}
          >
            Privacy Policy
          </Link>
        ) : (
          <Link
            href="#/privacy-policy"
            target="_blank"
            variant="caption"
            color="textPrimary"
            sx={{ color: color }}
          >
            Privacy Policy
          </Link>
        )}
        {terms_conditions ? (
          <Link
            variant="caption"
            color="textPrimary"
            onClick={() => {
              handleDownloadbase64ToFile(
                terms_conditions?.split(",")[1],
                "Terms-Conditions"
              );
            }}
            sx={{ color: color }}
          >
            Terms & Conditions
          </Link>
        ) : (
          <Link
            href="#/terms-conditions"
            target="_blank"
            variant="caption"
            color="textPrimary"
            sx={{ color: color }}
          >
            Terms & Conditions
          </Link>
        )}
        {about_us ? (
          <Link
            variant="caption"
            color="textPrimary"
            onClick={() => {
              handleDownloadbase64ToFile(about_us?.split(",")[1], "About-Us");
            }}
            sx={{ color: color }}
          >
            Contact Us
          </Link>
        ) : (
          <Link
            href="#/contact-us"
            target="_blank"
            variant="caption"
            color="textPrimary"
            sx={{ color: color }}
          >
            Contact Us
          </Link>
        )}
      </Stack>
    </Stack>
  );
};

export default Footer;
