import React, { useEffect, useState } from "react";

import { Descriptions } from "antd";
import MainCard from "../../../components/MainCard";
import { Grid, Tooltip } from "@mui/material";
import { get_flow_details, get_instruction_status } from "utils/api";
import {} from "utils/api";
import { toast } from "react-toastify";
import { Button as Button1 } from "antd";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import Timer from "../StopWatch";
import CostInvolved from "../ticket-details/CostInvolved";
import ActionInTicket from "../ticket-details/ActionInTicket";
import TimeLineData from "../ticket-details/TimeLine";
import ChatBox from "../ticket-details/ChatBox";
import ChecklistTable from "./ChecklistTable";

function ChecklistTicket() {
  const use_location = useLocation();
  const navigate = useNavigate();

  // const machine_id = use_location?.state?.machine_id;
  const ticket_id = use_location?.state?.ticket_id;
  const checklist_id = use_location?.state?.checklist_id;

  const [ticket_details, set_ticket_details] = useState(null);
  const [ticket_flow, set_ticket_fllow] = useState([]);
  const [unique_id, set_unique_id] = useState("");
  const [transaction_id, set_transaction_id] = useState("");

  useEffect(() => {
    get_flow_details(success_get_flow_details, ticket_id);
    get_instruction_status(success_set_instructions_list, ticket_id);
  }, [checklist_id, ticket_id]);

  const success_get_flow_details = (res) => {
    if (res.data.status === "success") {
      set_ticket_details(res?.data?.data[0].Main_details[0]);
      set_unique_id(res?.data?.data[0].ticket_unique_id);
      set_transaction_id(res?.data?.data[0].transaction_id);
      set_ticket_fllow(res?.data?.data[0].Flow1);
    }
  };

  const [instrutions_list, set_instructions_list] = useState([]);

  const success_set_instructions_list = (res) => {
    set_instructions_list(res.data.data);
    set_show_checklist(
      res.data.data?.some(
        (instruction) => instruction.instruction_name === "Completed"
      )
    );
  };

  const [is_started, set_is_started] = useState(false);
  const [show_checklist, set_show_checklist] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <Grid container>
      <Tooltip title="Support">
        <FloatButton
          icon={<QuestionCircleOutlined />}
          type="primary"
          style={{
            right: 24,
          }}
          onClick={() => setOpen(!open)}
        />
      </Tooltip>
      <Grid item xs={12} lg={9}>
        <MainCard title="Ticket Details" backbutton>
          <Grid container spacing={3}>
            <Grid item lg={12}>
              <Descriptions
                column={3}
                size="middle"
                contentStyle={{}}
                labelStyle={{
                  fontWeight: "800",
                }}
                bordered
                items={[
                  {
                    key: 2,
                    label: "Checklist",
                    children: ticket_details?.checklist_name,
                  },
                  {
                    key: 2,
                    label: "Title",
                    children: ticket_details?.Summary,
                  },
                  {
                    key: 3,
                    label: "Ticket Id",
                    children: unique_id,
                  },
                  {
                    key: 3,
                    label: "Priority",
                    children: ticket_details?.priority_level,
                  },
                  {
                    key: 4,
                    label: "Date",
                    children: ticket_details?.date?.slice(0, 19),
                  },
                  {
                    key: 5,
                    label: "Issue Type",
                    children: ticket_details?.issue_type,
                  },
                  {
                    key: 7,
                    label: "Location",
                    children: ticket_details?.location,
                  },
                  {
                    key: 8,
                    label: "Description",
                    children: ticket_details?.description,
                  },
                ]}
              />
            </Grid>
            {/* <Grid item lg={12}>
              <Stack direction="row" justifyContent="end">
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate("/report", {
                      state: {
                        machine_id: machine_id,
                      },
                    })
                  }
                >
                  Check history
                </Button>
              </Stack>
            </Grid> */}
          </Grid>

          {/* {is_started && ( */}
          <Grid
            container
            spacing={2}
            sx={{
              my: 5,
            }}
          >
            {(ticket_details?.ticket_status === "Completed" ||
              ticket_details?.ticket_status === "Closed" ||
              ticket_details?.ticket_status === "Verified" ||
              ticket_details?.ticket_status === "Resolved" ||
              instrutions_list?.some(
                (instruction) => instruction.instruction_name === "Completed"
              )) && (
              <Grid item lg={12}>
                <ChecklistTable
                  get_status={instrutions_list?.some(
                    (instruction) =>
                      instruction.instruction_name === "Completed"
                  )}
                  ticket_id={ticket_id}
                  checklist_id={checklist_id}
                  ticket_status={ticket_details?.ticket_status}
                />
              </Grid>
            )}
          </Grid>
          {/* )}  */}
          <Grid item lg={12}>
            <TimeLineData flow_data={ticket_flow} />
          </Grid>
        </MainCard>
      </Grid>
      <Grid container item xs={12} lg={3}>
        <Grid item lg={12}>
          {instrutions_list?.length > 0 &&
            !instrutions_list?.some(
              (instruction) => instruction.instruction_name === "Completed"
            ) && (
              <MainCard>
                <ActionInTicket
                  transaction_id={transaction_id}
                  instrutions_list={instrutions_list}
                />
              </MainCard>
            )}
          <MainCard>
            <CostInvolved history_ticket_id={ticket_id} />
          </MainCard>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ChecklistTicket;
